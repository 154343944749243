<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
    modelValue: String | Number,
    autofocus: {
        type: Boolean,
        default: false,
    }
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (props.autofocus) {
        input.value.focus();
    }
});

defineExpose({
    focus: () => input.value.focus(),
    select: () => input.value.select(),
    blur: () => input.value.blur(),
});
</script>

<template>
    <input
        class="border-gray-300 text-lg disabled:bg-gray-50 disabled:border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm"
        :value="modelValue ?? ''"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
    />
</template>
